import { HttpClient } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { config } from '../../app/config';
import { DepartmentModel } from '../models/department.model';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class OrganizationService {
    constructor(_http) {
        this._http = _http;
        this.onDepartmentsUpdated = new Subject();
        this.notifyDepartmentUpdate(null);
    }
    notifyDepartmentUpdate(departmentId) {
        this._http.get(config.apiEndPointBase + "organization/departments").subscribe((result) => {
            result.sort((x, y) => {
                return x.name.localeCompare(y.name);
            });
            this._departments = result;
            this.onDepartmentsUpdated.next(departmentId);
        });
    }
    getDepartments() {
        return this._departments;
    }
    getDepartment(id) {
        if (this._departments == null)
            return null;
        var items = this._departments.filter(x => x.id == id);
        if (items.length > 0) {
            return items[0];
        }
        else {
            return null;
        }
    }
    saveDepartment(data) {
        if (data.id) {
            return this._http
                .put(config.apiEndPointBase + "department", data, {})
                .pipe(map((response) => {
                return new DepartmentModel().deserialize(response);
            }));
        }
        else {
            return this._http
                .post(config.apiEndPointBase + "department", data, {})
                .pipe(map((response) => {
                return new DepartmentModel().deserialize(response);
            }));
        }
    }
    employees(data) {
        return this._http
            .post(config.apiEndPointBase + "organization/employees", data, {})
            .pipe(map((response) => {
            if (response.hasError) {
                throw new Error(response.message);
            }
            return response;
        }));
    }
    addStudent(student) {
        return new Observable((observer) => {
            setTimeout(() => {
                //console.log(student);
                observer.next({ me: 'hello' });
                observer.complete();
            }, 100);
        });
    }
    hods() {
        return this._http
            .post(config.apiEndPointBase + "organization/hods", {}, {})
            .pipe(map((response) => {
            response.sort((x, y) => {
                return x.name.localeCompare(y.name);
            });
            return response;
        }));
    }
    sendNotification(data) {
        return this._http
            .post(config.apiEndPointBase + "organization/employees", data, {})
            .pipe(map((response) => {
            return response;
        }));
    }
    saveRole(data) {
        if (data.id) {
            return this._http
                .put(config.apiEndPointBase + "organization/role", data, {})
                .pipe(map((response) => {
                return response;
            }));
        }
        else {
            return this._http
                .post(config.apiEndPointBase + "organization/role", data, {})
                .pipe(map((response) => {
                return response;
            }));
        }
    }
    setRole(data) {
        return this._http
            .put(config.apiEndPointBase + "organization/role/" + data.roleId + "/set", data, {})
            .pipe(map((response) => {
            return response;
        }));
    }
    unsetRole(data) {
        return this._http
            .put(config.apiEndPointBase + "organization/role/" + data.roleId + "/unset/" + data.employeeId, data, {})
            .pipe(map((response) => {
            return response;
        }));
    }
    getCapabilities() {
        return this._http.get(config.apiEndPointBase + "organization/capabilities", {});
    }
    getCapability(code) {
        return this._http.get(config.apiEndPointBase + "organization/capability/has/" + code, {});
    }
    getMyCapabilities() {
        return this._http.get(config.apiEndPointBase + "organization/my-capabilities", {});
    }
    getRoles() {
        return this._http.get(config.apiEndPointBase + "organization/roles", {});
    }
    getRole(roleId) {
        return this._http.get(config.apiEndPointBase + "organization/role/" + roleId, {});
    }
    getMyRoles() {
        return this._http.get(config.apiEndPointBase + "organization/my-roles", {});
    }
    getEmployeeRoles(employeeId) {
        return this._http.get(config.apiEndPointBase + "organization/roles/" + employeeId, {});
    }
}
OrganizationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OrganizationService_Factory() { return new OrganizationService(i0.ɵɵinject(i1.HttpClient)); }, token: OrganizationService, providedIn: "root" });
