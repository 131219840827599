import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizationService } from '../api-client/organization.service';
import { DepartmentService } from '../api-client/department.service';
import { AcademiaService } from '../api-client/academia.service';
import { paperService } from '../api-client/paper.service';
import { UiService } from '../ui.service';
import { config } from '../config';
import { ExamService } from '../api-client/exam.service';
import { ExportAsService } from 'ngx-export-as';
import { ToastrService } from 'ngx-toastr';
import { UtilityService } from '../api-client/utility.service';
export class ExaminersComponent {
    constructor(_route, _router, _organizationService, _academiaService, _paperService, _uiService, _examService, _exportAsService, _departmentService, _tosterService, _utilityService) {
        this._route = _route;
        this._router = _router;
        this._organizationService = _organizationService;
        this._academiaService = _academiaService;
        this._paperService = _paperService;
        this._uiService = _uiService;
        this._examService = _examService;
        this._exportAsService = _exportAsService;
        this._departmentService = _departmentService;
        this._tosterService = _tosterService;
        this._utilityService = _utilityService;
        this.setExaminer = [];
        this.exams = [];
        this.examiners = [];
        this.reviewers = [];
        this.thirdExaminers = [];
        this.marksEntryHeader = config.marksEntryHeader;
        this.students = [];
        this.populatingExams = false;
        this.selectedExaminerArray = [];
        this.selectedEvaluators = [];
        this.thirdExaminer = [];
        this._academiaService.loadInitialData();
        this._academiaService.getActiveExam().subscribe((result) => {
            this.activeExam = result;
        });
        this._academiaServiceSubscription = this._academiaService.onAcademicSessionUpdate.subscribe(_ => {
            let activeSession = this._academiaService.getActiveAcademicSession();
            if (this.selectedSession == null || this.selectedSession.id != activeSession.id) {
                this.selectedSession = this._academiaService.getActiveAcademicSession();
                this.getExams(this.selectedSession);
            }
        });
        this._utilityService.getEmpList.subscribe((data) => {
            this.selectedExaminerArray = data;
        });
        this._utilityService.getReviewerList.subscribe((data) => {
            this.selectedReviewerArray = data;
        });
        this._utilityService.getThirdExaminerList.subscribe((data) => {
            this.selectedThirdExaminerArray = data;
        });
    }
    ngOnInit() {
        this.selectedSession = JSON.parse(localStorage.getItem("selectSession"));
        this.getExams(this.selectedSession);
    }
    ngOnChanges(changes) {
        if (!this.selectedSession)
            return;
        this.getExams(this.selectedSession);
    }
    getExams(session) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!session)
                return;
            if (this.populatingExams)
                return;
            this.populatingExams = true;
            yield this._academiaService.getSessionWiseExams(session.id).toPromise().then((result) => {
                this.exams = result;
                this.examiners = [];
                this.reviewers = [];
                this.thirdExaminers = [];
                if (this.activeExam.academicSessionId == this.selectedSession.id) {
                    this.selectExam(this.activeExam.id);
                }
                this.populatingExams = false;
            });
        });
    }
    selectExam(examId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!examId)
                return null;
            yield this._paperService.getEvaluators(this.paper.id, examId).toPromise().then((result) => {
                this.selectedEvaluators = result;
                this.examiners = [];
                this.reviewers = [];
                this.thirdExaminers = [];
                for (var i = 0; i < result.length; i++) {
                    if (result[i].type == 2) {
                        this.examiners.push(result[i]);
                    }
                    else if (result[i].type == 4) {
                        this.reviewers.push(result[i]);
                    }
                    else {
                        this.thirdExaminers.push(result[i]);
                    }
                }
            });
            console.log('this.examiners', this.examiners);
        });
    }
    previewCsvData(fieldMaps, results) {
        this._uiService.indeterminateProgressTextSet.next('Arrange data...');
        var columns = [];
        var columnsWidth = {};
        var columnSources = [];
        columnSources['paperType'] = Object.keys(config.paperType).map((key) => {
            return { id: Number(key), name: config.paperType[key] };
        });
        columnSources['level'] = Object.keys(config.academicLevel).map((key) => {
            return { id: Number(key), name: config.academicLevel[key] };
        });
        columnSources['secondaryPaperType'] = Object.keys(config.secondaryPaperType).map((key) => {
            return { id: Number(key), name: config.secondaryPaperType[key] };
        });
        columnSources['academicSessionId'] = this._academiaService.getAcademicSession().map((x) => {
            return { id: x.id, name: x.name };
        });
        columnSources['disciplineId'] = this._academiaService.getDisciplines().map((x) => {
            return { id: x.id, name: x.name };
        });
        var arrangedData = results.map((value) => {
            var item = {};
            for (var key in fieldMaps) {
                var valueWidth = (value[fieldMaps[key]] + '').length * 15;
                item[key] = value[fieldMaps[key]];
                if (!columnsWidth[key] || columnsWidth[key] < valueWidth) {
                    columnsWidth[key] = valueWidth;
                }
            }
            return item;
        });
        this._uiService.replaceCsvValueWithId(columnSources, arrangedData);
        this._uiService.createDataPreviewColumns(fieldMaps, columnsWidth, columns, columnSources);
        columnsWidth = null;
        this._uiService.indeterminateProgressTextSet.next(null);
        this._uiService.openDataPreviewWindow(arrangedData, columns, [
            {
                type: 'i',
                content: 'done_all',
                onclick: () => {
                    this.onImportPreviewCompleted(Object.keys(fieldMaps), arrangedData, columns);
                }
            }
        ]);
    }
    onImportPreviewCompleted(fields, arrangedData, columns) {
        var data = this._uiService.getPreviewModifiedData();
        var converted = data.map((value) => {
            var item = {};
            for (var i = 0; i < fields.length; i++) {
                item[fields[i]] = value[i];
            }
            return item;
        });
        this._uiService.closeDataPreview();
        var worker = new Worker('../workers/import-marks.worker', { name: 'marks-worker', type: 'module' });
        this._uiService.startWorker(worker, { values: converted, paperId: this.paper.id, examiner: this.examiner, token: sessionStorage.getItem("token") })
            .then(() => {
            window.location.href = "/paper/" + this.paper.id;
        }).catch(e => {
            if (e.validation) {
                this.onImportValidationError(e.validation, converted, columns, fields);
            }
        });
    }
    onImportValidationError(validation, converted, columns, fields) {
        this._uiService.onDataPreviewReady = () => {
            var allFields = Object.keys(converted[0]);
            for (var i = 0; i < validation.length; i++) {
                var commonErrors = [];
                for (var field in validation[i]) {
                    var fieldIndex = allFields.indexOf(field);
                    if (fieldIndex <= 0) {
                        commonErrors = commonErrors.concat(validation[i][field]);
                    }
                    else {
                        this._uiService.setPreviewDataCellCommentAndStyle(i + 1, fieldIndex + 1, validation[i][field].join('\n'), null);
                    }
                }
                if (commonErrors.length > 0) {
                    this._uiService.setPreviewDataCellCommentAndStyle(i + 1, 1, commonErrors.join('\n'), null);
                }
            }
        };
        this._uiService.openDataPreviewWindow(converted, columns, [
            {
                type: 'i',
                content: 'done_all',
                onclick: () => {
                    this.onImportPreviewCompleted(fields, converted, columns);
                }
            }
        ]);
    }
    mapCsvField(results) {
        this._uiService
            .mapCsvField(results.meta.fields, this.marksEntryHeader)
            .then((maps) => {
            this.previewCsvData(maps, results.data);
        }).catch(() => { });
    }
    uploadFile(fileEle, examiner) {
        this.examiner = examiner;
        this._uiService
            .parseCsv(fileEle.files[0])
            .then((results) => {
            fileEle.value = null;
            this.mapCsvField(results);
        }).catch(() => {
            fileEle.value = null;
        });
    }
    downloadCsv(examinationId, paperId, evaluatorId, mode, term) {
        this._examService.downloadSubmitMarks(examinationId, paperId, evaluatorId, mode, term).subscribe((result) => {
            var students = [];
            for (var i = 0; i < result.length; i++) {
                /* var term = "";
                if (result[i].isMidTermMarks) {
                  term = "Mid";
                } else {
                  term = "End";
                } */
                var studentMarks = "";
                if (result[i].studentAbsent) {
                    studentMarks = "Ab";
                }
                else {
                    studentMarks = result[i].marks;
                }
                students.push({
                    registrationNumber: result[i].student.registrationNumber,
                    batchStartYear: result[i].student.batchStartYear,
                    code: result[i].paper.code,
                    semester: result[i].paper.semester,
                    session: result[i].evaluator.paperSessionName,
                    examinerName: result[i].evaluator.examinerName,
                    examinationName: result[i].examinationName,
                    marks: studentMarks,
                    mode: config.evaluatorType[result[i].mode],
                    term: term
                });
            }
            this.students = students;
            setTimeout(() => {
                this._exportAsService.save({ type: "csv", elementId: "studentMarks" }, this.students[0].code + "(" + this.students[0].session + ")").subscribe(() => {
                });
            }, 10);
        });
    }
    addExaminer(type) {
        this._router.navigate([{
                outlets: { rightMainPopup: ['examiner-list', { id: this.paper.disciplineId, type: type, paperId: this.paper.id, examId: this.activeExam.id }] }
            }]);
    }
    removeEmp(item) {
        this.selectedExaminerArray.splice(this.selectedExaminerArray.indexOf(item), 1);
    }
    removeReviewer(item) {
        this.selectedReviewerArray.splice(this.selectedReviewerArray.indexOf(item), 1);
    }
    removeThirdExaminer(item) {
        this.selectedThirdExaminerArray.splice(this.selectedThirdExaminerArray.indexOf(item), 1);
    }
    saveReviewer() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.saveData(this.selectedReviewerArray, 4);
        });
    }
    saveExaminer() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.saveData(this.selectedExaminerArray, 2);
        });
    }
    saveThirdExaminer() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.saveData(this.selectedThirdExaminerArray, 104);
        });
    }
    saveData(arrayData, type) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            var temp = [];
            temp = temp.concat(this.selectedEvaluators);
            var endMarks = 0;
            this.session = this._academiaService.getSessionDetails(this.paper.academicSessionId);
            var update = yield arrayData.forEach((data, index) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                let submit = {
                    paperId: this.paper.id,
                    paperCode: this.paper.code,
                    paperName: this.paper.name,
                    paperSessionId: this.paper.academicSessionId,
                    paperSessionName: this.session.name,
                    examinationId: this.activeExam.id,
                    examinerId: data.item.id,
                    examinerName: data.item.name,
                    type: type,
                    alocatedEndTermMarks: Number(data.uploadedEndMarks),
                    allowSubmitMidTermMarks: data.uploadedMidMarks,
                    midTermMarksSubmitedOn: '',
                    endTermMarksSubmitedOn: '',
                    allowSubmitEndTermMarks: data.uploadedEndTermMarks
                };
                temp.push(submit);
                endMarks = endMarks + Number(data.uploadedEndMarks);
            }));
            if (this.paper.endTermAllocatedMarks < endMarks) {
                this._tosterService.error("Allocated End term marks is too large");
                return;
            }
            var dataSubmit = yield this._examService.setEvaluators(temp).toPromise();
            this.selectExam(this.activeExam.id);
            if (type == 2) {
                this.selectedExaminerArray = [];
                this._tosterService.success("Successfully allocated examiner");
            }
            else if (type == 4) {
                this.selectedReviewerArray = [];
                this._tosterService.success("Successfully allocated reviewer");
            }
            else {
                this.selectedThirdExaminerArray = [];
                this._tosterService.success("Successfully allocated third examiner");
            }
        });
    }
    removeData(item) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.selectedEvaluators.splice(this.selectedEvaluators.indexOf(item), 1);
            var dataSubmit = yield this._examService.setEvaluators(this.selectedEvaluators).toPromise();
            this.selectExam(this.activeExam.id);
        });
    }
    selectedItem(employ, i) {
        const midTerm = this.examiners.filter((x) => x.allowSubmitMidTermMarks);
        if (midTerm.length == 0) {
            this.selectedExaminerArray.forEach((data, index) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                data.uploadedMidMarks = false;
            }));
            this.selectedExaminerArray[i]['uploadedMidMarks'] = true;
        }
        else {
            setTimeout(() => {
                this.selectedExaminerArray[i]['uploadedMidMarks'] = false;
            }, 100);
            this._tosterService.error("Midterm coordinator is already allocated");
        }
    }
    selectedEndTermItem(employ, i) {
        const endTerm = this.examiners.filter((x) => x.allowSubmitEndTermMarks);
        if (endTerm.length == 0) {
            this.selectedExaminerArray.forEach((data, index) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                data.uploadedEndTermMarks = false;
            }));
            this.selectedExaminerArray[i]['uploadedEndTermMarks'] = true;
        }
        else {
            setTimeout(() => {
                this.selectedExaminerArray[i]['uploadedEndTermMarks'] = false;
            }, 100);
            this._tosterService.error("Endterm coordinator is already allocated");
        }
    }
    updateDate(paperId, examinationId, mode, term, evaluatorId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this._paperService.updateMarksUploadDate(paperId, examinationId, mode, term, evaluatorId).toPromise().then((result) => {
                window.location.href = "/paper/" + this.paper.id;
            });
        });
    }
    ngOnDestroy() {
        //this._paramsChangeSubscriber.unsubscribe();
        //this._organizationServiceSubscription.unsubscribe();
        this._academiaServiceSubscription.unsubscribe();
    }
}
