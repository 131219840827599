import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export class LoginGuardService {
    constructor(_router) {
        this._router = _router;
    }
    canActivate(next, state) {
        // let authDecode = localStorage.getItem("auth");
        let authDecode = sessionStorage.getItem("token");
        if (authDecode) {
            return false;
        }
        return true;
    }
}
LoginGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoginGuardService_Factory() { return new LoginGuardService(i0.ɵɵinject(i1.Router)); }, token: LoginGuardService, providedIn: "root" });
