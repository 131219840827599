import { FormBuilder, Validators } from '@angular/forms';
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
export class OrganizationFormBuilder {
    constructor(fb) {
        this.fb = fb;
        this.validationRules = {
            id: ['', ''],
            parent: ['', Validators.required],
            name: ['', Validators.required]
        };
        this.form = fb.group(this.validationRules);
    }
    getForm() {
        return this.form;
    }
    setValues(response) {
        this.form.patchValue({
            id: response.id,
            parent: response.parent,
            name: response.name
        });
    }
}
OrganizationFormBuilder.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OrganizationFormBuilder_Factory() { return new OrganizationFormBuilder(i0.ɵɵinject(i1.FormBuilder)); }, token: OrganizationFormBuilder, providedIn: "root" });
