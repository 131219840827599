import { FormBuilder, Validators } from '@angular/forms';
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
export class TemplateFormBuilder {
    constructor(fb) {
        this.fb = fb;
        this.validationRules = {
            id: ['', ''],
            subject: ['', Validators.required],
            emailContent: ['', Validators.required],
            smsContent: ['', ''],
            tag: ['', ''],
            params: ['', ''],
            pdfContent: ['', '']
        };
        this.form = fb.group(this.validationRules);
    }
    getForm() {
        return this.form;
    }
    setValues(response) {
        this.form.patchValue({
            id: response.id,
            subject: response.subject,
            emailContent: response.emailContent,
            smsContent: response.smsContent,
            tag: response.tag,
            params: response.params,
            pdfContent: response.pdfContent
        });
    }
}
TemplateFormBuilder.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TemplateFormBuilder_Factory() { return new TemplateFormBuilder(i0.ɵɵinject(i1.FormBuilder)); }, token: TemplateFormBuilder, providedIn: "root" });
