import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { config } from '../../app/config';
import { ProgramModel } from '../models/program.model';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ProgramService {
    constructor(_http) {
        this._http = _http;
    }
    getSessionWisePapers(programId, sessionId) {
        return this._http.get(config.apiEndPointBase + "program/" + programId + "/papers-in-session/" + sessionId);
    }
    getSessionWiseStudents(programId, sessionId) {
        return this._http.get(config.apiEndPointBase + "program/" + programId + "/students-in-session/" + sessionId);
    }
    getProgremWiseStudentsPursue(programId, startIndex, pageSize) {
        return this._http.get(`${config.apiEndPointBase}program/${programId}/students-pursue?startIndex=${startIndex}&pageSize=${pageSize}`);
    }
    getProgram(id) {
        return this._http.get(config.apiEndPointBase + "program/" + id);
    }
    saveProgram(data) {
        if (data.id) {
            return this._http
                .put(config.apiEndPointBase + "program", data, {})
                .pipe(map((response) => {
                return new ProgramModel().deserialize(response);
            }));
        }
        else {
            return this._http
                .post(config.apiEndPointBase + "program", data, {})
                .pipe(map((response) => {
                return new ProgramModel().deserialize(response);
            }));
        }
    }
}
ProgramService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProgramService_Factory() { return new ProgramService(i0.ɵɵinject(i1.HttpClient)); }, token: ProgramService, providedIn: "root" });
