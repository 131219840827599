import * as signalR from '@aspnet/signalr';
import { config } from '../config';
import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
export class NotifyService {
    constructor() {
        this.onStudentInfUpdateNotify = new Subject();
        this.onPaperInfoUpdateNotify = new Subject();
        this.onSessionInfoUpdateNotify = new Subject();
        var url = config.apiEndPointBase.replace('api/', '');
        this._hubConnection = new signalR
            .HubConnectionBuilder()
            .withUrl(`${url}notify`, {
            skipNegotiation: true,
            transport: signalR.HttpTransportType.WebSockets
        })
            .build();
        // this._hubConnection
        //   .start()
        //   .then(() => this.configNotifyListener())
        //   .catch((e) => alert(e));
    }
    configNotifyListener() {
        // this._hubConnection.on('StudentInfoUpdated', this.onStudentInfoUpdated.bind(this));
        // this._hubConnection.on('PaperInfoUpdated', this.onPaperInfoUpdated.bind(this));
        // this._hubConnection.on('SessionInfoUpdated', this.onSessionInfoUpdated.bind(this));
    }
    onStudentInfoUpdated(student) {
        this.onStudentInfUpdateNotify.next(student);
    }
    onPaperInfoUpdated(paper) {
        this.onPaperInfoUpdateNotify.next(paper);
    }
    onSessionInfoUpdated(session) {
        this.onSessionInfoUpdateNotify.next(session);
    }
    studentUpdated(student) {
        this._hubConnection
            .invoke("StudentInfoUpdated", student)
            .then((response) => console.log(response))
            .catch((error) => console.error(error));
    }
    paperUpdated(paper) {
        this._hubConnection
            .invoke("PaperInfoUpdated", paper)
            .then((response) => console.log(response))
            .catch((error) => console.error(error));
    }
    sessionUpdated(session) {
        this._hubConnection
            .invoke("SessionInfoUpdated", session)
            .then((response) => console.log(response))
            .catch((error) => console.error(error));
    }
}
NotifyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotifyService_Factory() { return new NotifyService(); }, token: NotifyService, providedIn: "root" });
