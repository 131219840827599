import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { AcademiaService } from '../api-client/academia.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UiService } from '../ui.service';
import { ExportAsService } from 'ngx-export-as';
import { ToastrService } from 'ngx-toastr';
import { paperService } from '../api-client/paper.service';
export class SearchStudentsComponent {
    constructor(_academiaService, _route, _router, _uiService, _paperService, _exportAsService, _tosterService) {
        this._academiaService = _academiaService;
        this._route = _route;
        this._router = _router;
        this._uiService = _uiService;
        this._paperService = _paperService;
        this._exportAsService = _exportAsService;
        this._tosterService = _tosterService;
        this._route.params.subscribe((args) => {
            this.paperId = args.id;
            this.studentsInPaper();
            this.paperDetails();
        });
    }
    ngOnInit() {
    }
    paperDetails() {
        this._paperService.getPaper(this.paperId).subscribe((result) => {
            this.paper = result;
            this.academicSessionId = result.academicSessionId;
            this.currentSemester = result.semester;
        });
    }
    studentSearch() {
        if (this.freeText == "" || this.freeText == null) {
            this._tosterService.error("Student reg.no or name can not be blank.");
            return;
        }
        var filters = {
            "freeText": this.freeText,
            "academicSessionId": this.academicSessionId,
            "programIds": [],
            //"semester": this.currentSemester,
            "semester": 0,
            "batchStartYear": 0,
            "registrationYear": 0,
            "startIndex": 0,
            "pageSize": 0
        };
        this._academiaService.students(filters).subscribe((result) => {
            for (var i = 0; i < result.items.length; i++) {
                var student = this.studentsInpaper.filter((x) => x.id == result.items[i].id);
                if (student.length > 0) {
                    result.items[i].assigned = true;
                }
            }
            this.students = result.items;
        });
    }
    studentsInPaper() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this._academiaService.getPaperWiseStudents(this.paperId).toPromise().then((result) => {
                this.studentsInpaper = result;
            });
        });
    }
    assignStudent(student) {
        var filter = {
            "studentId": student.id,
            "paperCodes": [this.paper.code]
        };
        this._academiaService.setStudentPaper(filter).subscribe((result) => {
            this._tosterService.success("Student set in this paper");
            this.studentsInPaper();
            this.studentSearch();
            return;
        });
    }
}
