import { HttpClient } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { config } from '../config';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class UtilityService {
    constructor(_http) {
        this._http = _http;
        this.onTemplatesUpdated = new Subject();
        this.getEmpList = new BehaviorSubject({ data: false });
        this.getReviewerList = new BehaviorSubject({ data: false });
        this.getThirdExaminerList = new BehaviorSubject({ data: false });
        _http.get(config.apiEndPointBase + "utility/notification-templates").subscribe((result) => {
            this._templates = result;
        });
    }
    submittedEmpArray(listValue) {
        this.getEmpList.next(listValue);
    }
    submittedReviewerArray(listValue) {
        this.getReviewerList.next(listValue);
    }
    submittedThirdExaminerArray(listValue) {
        this.getThirdExaminerList.next(listValue);
    }
    getTemplate(id) {
        if (this._templates == null)
            return null;
        var items = this._templates.filter((x) => x.id == id);
        if (items.length > 0) {
            return items[0];
        }
        else {
            return null;
        }
    }
    getTemplates() {
        return this._http.get(config.apiEndPointBase + "utility/notification-templates");
    }
    save(data) {
        if (data.id) {
            return this._http
                .put(config.apiEndPointBase + "utility", data, {})
                .pipe(map((response) => {
                return response;
            }));
        } /* else {
          return this._http
            .post(config.apiEndPointBase + "utility", data, {})
            .pipe(map((response: any) => {
              return response;
            }));
        } */
    }
    sendNotification(department) {
        return this._http
            .post(config.apiEndPointBase + "action/send-examiner-allocation-request/" + department.departmentId, { departmentId: department.departmentId }, {})
            .pipe(map((response) => {
            return response;
        }));
    }
    stopNotification(department) {
        return this._http
            .put(config.apiEndPointBase + "action/cancel-examiner-allocation-request/" + department.departmentId, { departmentId: department.departmentId }, {})
            .pipe(map((response) => {
            return response;
        }));
    }
    activeExaminerDepartments() {
        return this._http
            .post(config.apiEndPointBase + "action/active-examiner-allocation-requests", {}, {})
            .pipe(map((response) => {
            return response;
        }));
    }
    unsetEvalutors(data) {
        return this._http
            .put(config.apiEndPointBase + "action/unset-evaluators/" + data.examinationId + "/" + data.id, {}, {})
            .pipe(map((response) => {
            return response;
        }));
    }
}
UtilityService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UtilityService_Factory() { return new UtilityService(i0.ɵɵinject(i1.HttpClient)); }, token: UtilityService, providedIn: "root" });
