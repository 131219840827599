import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { Papa } from 'ngx-papaparse';
import { ToastrService } from 'ngx-toastr';
import { config } from '../app/config';
import * as i0 from "@angular/core";
import * as i1 from "ngx-toastr";
import * as i2 from "@angular/router";
import * as i3 from "ngx-papaparse";
export class UiService {
    constructor(_toster, _router, _papa) {
        this._toster = _toster;
        this._router = _router;
        this._papa = _papa;
        this.showHeader = new Subject();
        this.changeAppTitle = new Subject();
        this.detailOutletUpdateForId = new Subject();
        this.quickItemsSet = new Subject();
        this.indeterminateProgressTextSet = new Subject();
        this.assignEmployeeInDepartmentDiscpline = new Subject();
        this.assignEmployeeInDiscpline = new Subject();
        this.departmentEmployeesSet = new Subject();
    }
    setQuickItems(items) {
        this.quickItemsSet.next(items);
    }
    parseCsv(file) {
        var counter = 0;
        var items = [];
        var errors = [];
        var meta = null;
        this.indeterminateProgressTextSet.next('Reading csv...');
        return new Promise((resolve, reject) => {
            this._papa.parse(file, {
                header: true,
                dynamicTyping: true,
                worker: true,
                delimiter: ',',
                skipEmptyLines: true,
                step: (results, parser) => {
                    //console.log(results);
                    if (results.errors.length > 0) {
                        errors.push(results.errors[0]);
                        parser.abort();
                        return;
                    }
                    items.push(results.data);
                    if (meta == null) {
                        meta = results.meta;
                    }
                    counter++;
                    this.indeterminateProgressTextSet.next(`${counter} row(s) read from csv...`);
                },
                complete: () => {
                    if (errors && errors.length > 0) {
                        this._toster.error('Unable to parse this file. Please upload valid CSV. ' + errors[0].message);
                        if (reject)
                            reject(errors[0].message);
                    }
                    else {
                        resolve({ data: items, errors: errors, meta: meta });
                    }
                    this.indeterminateProgressTextSet.next(null);
                }
            });
        });
    }
    mapCsvField(headers, fields) {
        this.csvHeaders = headers;
        this.csvTargetFields = fields;
        return new Promise((resolve, reject) => {
            this.afterCsvMapDone = (fieldMap) => {
                if (fieldMap.error) {
                    if (reject)
                        reject(fieldMap.error);
                }
                else {
                    resolve(fieldMap.maps);
                }
            };
            this._router.navigate([{
                    outlets: { centerPopUp: ['data-import-mapper'] }
                }]);
        });
    }
    replaceCsvValueWithId(sources, data) {
        for (var i = 0; i < data.length; i++) {
            for (var key in sources) {
                if (data[i][key]) {
                    var values = sources[key].filter((x) => x.name == data[i][key]);
                    if (values.length > 0) {
                        data[i][key] = values[0].id;
                    }
                }
            }
        }
    }
    createDataPreviewColumns(fieldMaps, columnsWidth, columns, columnSources) {
        for (var key in fieldMaps) {
            var title = key;
            title = title.replace(/([A-Z])/g, " $1");
            title = title.charAt(0).toUpperCase() + title.slice(1);
            if (columnsWidth[key] < title.length * 15) {
                columnsWidth[key] = title.length * 15;
            }
            if (columnSources[key]) {
                columns.push({
                    title: title,
                    type: 'dropdown',
                    width: columnsWidth[key],
                    source: columnSources[key]
                });
            }
            else {
                columns.push({
                    title: title,
                    width: columnsWidth[key],
                });
            }
        }
    }
    openDataPreviewWindow(data, columns, actionButtons) {
        this.previewData = { data: data, columns: columns, actionButtons: actionButtons };
        this._router.navigate(['data-preview']);
    }
    setIndeterminateProgressText(worker, text) {
        this.indeterminateProgressTextSet.next(text);
    }
    showWorkerError(worker, error) {
        this._toster.error(error);
        worker.terminate();
    }
    showWorkerCompleteMessage(worker, error) {
        this._toster.success(error);
        worker.terminate();
    }
    startWorker(worker, initParams) {
        return new Promise((resolve, reject) => {
            worker.postMessage({ method: 'init', params: initParams, authToken: sessionStorage.getItem("token"), config: config });
            worker.onmessage = (event) => {
                var data = event.data;
                if (data.method && this[data.method]) {
                    this[data.method](worker, data.params);
                    if (data.method == 'showWorkerCompleteMessage') {
                        resolve(null);
                    }
                }
                else if (data.method && data.method == 'errors') {
                    reject(data.params);
                    worker.terminate();
                }
            };
            worker.onerror = (event) => {
                console.error('worker on error', event);
                worker.terminate();
                reject(event);
            };
        });
    }
    setEmployeeDepartmentDiscpline(items) {
        this.assignEmployeeInDepartmentDiscpline.next(items);
    }
    setEmployeeDiscpline(items) {
        this.assignEmployeeInDiscpline.next(items);
    }
    setDepartmentWiseEmployee(items) {
        this.departmentEmployees = items;
        //this.departmentEmployeesSet.next(items);
    }
    setDepartmentId(id) {
        this.departmentId = id;
    }
    setpaperInDisciplineSession(ele) {
        this.paperInDisciplineSession = ele;
    }
}
UiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UiService_Factory() { return new UiService(i0.ɵɵinject(i1.ToastrService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.Papa)); }, token: UiService, providedIn: "root" });
