import { FormBuilder, Validators } from '@angular/forms';
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
export class SessionFormBuilder {
    constructor(fb) {
        this.fb = fb;
        this.validationRules = {
            id: ['', ''],
            start: ['', Validators.required],
            end: ['', Validators.required],
            name: ['', Validators.required],
            active: ['', '']
        };
        this.form = fb.group(this.validationRules);
    }
    getForm() {
        return this.form;
    }
    setValues(response) {
        this.form.patchValue({
            id: response.id,
            name: response.name,
            start: response.start,
            end: response.end,
            active: response.active
        });
    }
}
SessionFormBuilder.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SessionFormBuilder_Factory() { return new SessionFormBuilder(i0.ɵɵinject(i1.FormBuilder)); }, token: SessionFormBuilder, providedIn: "root" });
