import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { config } from '../../app/config';
import { StudentModel } from '../models/student.model';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class StudentService {
    constructor(_http) {
        this._http = _http;
        this.onStudentsUpdated = new Subject();
        _http.get(config.apiEndPointBase + "organization/departments").subscribe((result) => {
            result.sort((x, y) => {
                return x.name.localeCompare(y.name);
            });
            this._students = result;
            this.onStudentsUpdated.next();
        });
    }
    getStudents() {
        return this._students;
    }
    getStudent(studentId) {
        return this._http.get(config.apiEndPointBase + "student/" + studentId);
    }
    getEvaluation(studentId) {
        return this._http.get(config.apiEndPointBase + "student/" + studentId + "/evaluation");
    }
    getHistory(studentId) {
        return this._http.get(config.apiEndPointBase + "student/" + studentId + "/marks-history");
    }
    saveStudent(data) {
        if (data.id) {
            return this._http
                .put(config.apiEndPointBase + "student", data, {})
                .pipe(map((response) => {
                return new StudentModel().deserialize(response);
            }));
        }
        else {
            return this._http
                .post(config.apiEndPointBase + "student", data, {})
                .pipe(map((response) => {
                return new StudentModel().deserialize(response);
            }));
        }
    }
    getStatisticsReport(examinationId) {
        return this._http.get(config.apiEndPointBase + "Examination/statistics/" + examinationId);
    }
    getStudentSemisterPassExam(studentId) {
        return this._http.get(config.apiEndPointBase + "Student/" + studentId + "/semester-pass-on-examination");
    }
}
StudentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StudentService_Factory() { return new StudentService(i0.ɵɵinject(i1.HttpClient)); }, token: StudentService, providedIn: "root" });
