import { environment } from '../environments/environment';
const ɵ0 = {
    scriptPath: '/assets/papaparse.min.js'
};
export class AppModule {
    constructor() {
        if (ENV_OVERWRITE) {
            for (const key in ENV_OVERWRITE) {
                environment[key] = ENV_OVERWRITE[key];
            }
        }
    }
}
export { ɵ0 };
