import { HttpClient } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { config } from '../config';
import { map } from 'rxjs/operators';
import { SessionModel } from '../models/session.model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class SessionService {
    constructor(_http) {
        this._http = _http;
        this.onSessionsUpdated = new Subject();
    }
    getSession(id) {
        return this._http.get(config.apiEndPointBase + "session/" + id);
    }
    saveSession(data) {
        if (data.id) {
            return this._http
                .put(config.apiEndPointBase + "session", data, {})
                .pipe(map((response) => {
                return new SessionModel().deserialize(response);
            }));
        }
        else {
            return this._http
                .post(config.apiEndPointBase + "session", data, {})
                .pipe(map((response) => {
                return new SessionModel().deserialize(response);
            }));
        }
    }
}
SessionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SessionService_Factory() { return new SessionService(i0.ɵɵinject(i1.HttpClient)); }, token: SessionService, providedIn: "root" });
