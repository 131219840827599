import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { config } from '../../app/config';
import { SessionModel } from '../models/session.model';
import { StudentModel } from '../models/student.model';
import { DisciplineModel } from '../models/discipline.model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class AcademiaService {
    constructor(_http) {
        this._http = _http;
        this.onProgramsUpdated = new Subject();
        this.onAcademicSessionUpdate = new Subject();
        this.onDisciplinesUpdated = new Subject();
        this.loadInitialData();
    }
    loadInitialData() {
        const _http = this._http;
        // Don't call these APIs if user not logged in
        const userToken = sessionStorage.getItem("token");
        if (userToken && userToken != "") {
            _http.get(config.apiEndPointBase + "academia/sessions").subscribe((result) => {
                result.sort((x, y) => {
                    return x.name.localeCompare(y.name);
                });
                this._academicSession = result;
                let storedSelectedSession = localStorage.getItem("selectSession");
                if (storedSelectedSession) {
                    this._selectedAcademicSession = JSON.parse(storedSelectedSession);
                }
                else {
                    var selectedSessions = result.filter((x) => x.active);
                    if (selectedSessions.length > 0) {
                        this._selectedAcademicSession = selectedSessions[0];
                    }
                }
                this.onAcademicSessionUpdate.next();
            });
            _http.get(config.apiEndPointBase + "academia/programs").subscribe((result) => {
                result.sort((x, y) => {
                    return x.name.localeCompare(y.name);
                });
                this._programs = result;
                this.onProgramsUpdated.next();
            });
            _http.get(config.apiEndPointBase + "academia/disciplines").subscribe((result) => {
                result.sort((x, y) => {
                    return x.name.localeCompare(y.name);
                });
                this._disciplines = result;
                this.onDisciplinesUpdated.next();
            });
        }
    }
    getPrograms() {
        return this._programs;
    }
    getProgram(id) {
        if (this._programs == null)
            return null;
        var items = this._programs.filter((x) => x.id == id);
        if (items.length > 0) {
            return items[0];
        }
        else {
            return null;
        }
    }
    getDisciplines() {
        return this._disciplines;
    }
    getDiscipline(id) {
        if (this._disciplines == null)
            return null;
        var items = this._disciplines.filter((x) => x.id == id);
        if (items.length > 0) {
            return items[0];
        }
        else {
            return null;
        }
    }
    getAcademicSession() {
        return this._academicSession;
    }
    getActiveAcademicSession() {
        return this._selectedAcademicSession;
    }
    setSelectedSession(session) {
        if (this._selectedAcademicSession == null || this._selectedAcademicSession.id != session.id) {
            localStorage.setItem("selectSession", JSON.stringify(session));
            this._selectedAcademicSession = session;
            this.onAcademicSessionUpdate.next();
        }
    }
    getSessionDetails(sessionId) {
        if (this._academicSession == null)
            return null;
        var items = this._academicSession.filter((x) => x.id == sessionId);
        if (items.length > 0) {
            return items[0];
        }
        else {
            return null;
        }
    }
    getSessionWiseExams(sessionId) {
        return this._http.get(config.apiEndPointBase + "academia/examinations-in-session/" + sessionId);
    }
    saveSession(data) {
        return this._http
            .post(config.apiEndPointBase + "academia/session", data, {})
            .pipe(map((response) => {
            if (response.hasError) {
                throw new Error(response.message);
            }
            return new SessionModel().deserialize(response);
        }));
    }
    papers(data) {
        return this._http
            .post(config.apiEndPointBase + "academia/papers-with-active-examiners", data, {})
            .pipe(map((response) => {
            if (response.hasError) {
                throw new Error(response.message);
            }
            return response;
        }));
    }
    students(data) {
        return this._http
            .post(config.apiEndPointBase + "academia/students", data, {})
            .pipe(map((response) => {
            if (response.hasError) {
                throw new Error(response.message);
            }
            return response;
        }));
    }
    saveStudent(data) {
        return this._http
            .post(config.apiEndPointBase + "academia/session", data, {})
            .pipe(map((response) => {
            if (response.hasError) {
                throw new Error(response.message);
            }
            return new StudentModel().deserialize(response);
        }));
    }
    updateStudentCurrentSemester(date) {
        var dateStr = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
        return this._http
            .put(config.apiEndPointBase + "academia/students/update-current-semester/" + dateStr, {}, {})
            .pipe(map((response) => {
            if (response.hasError) {
                throw new Error(response.message);
            }
            return response;
        }));
    }
    sessionWisepaperCopy(data) {
        return this._http
            .post(config.apiEndPointBase + "academia/papers/copy-from-session/" + data.sourceSessionId + "/" + data.targetSessionId, {}, {})
            .pipe(map((response) => {
            if (response.hasError) {
                throw new Error(response.message);
            }
            return response;
        }));
    }
    saveDiscipline(data) {
        return this._http
            .post(config.apiEndPointBase + "academia/discipline", data, {})
            .pipe(map((response) => {
            if (response.hasError) {
                throw new Error(response.message);
            }
            return new DisciplineModel().deserialize(response);
        }));
    }
    unsetStudentPaper(data) {
        return this._http
            .post(config.apiEndPointBase + "academia/unset-student-papers", data, {})
            .pipe(map((response) => {
            return response;
        }));
    }
    setStudentPaper(data) {
        return this._http
            .post(config.apiEndPointBase + "academia/set-student-papers", data, {})
            .pipe(map((response) => {
            return response;
        }));
    }
    getPaperWiseStudents(paperId) {
        return this._http.get(config.apiEndPointBase + "academia/students-in-paper/" + paperId);
    }
    getBacklogPaperWiseStudents(paperId) {
        return this._http.get(config.apiEndPointBase + "academia/students-have-backlog-in-paper/" + paperId);
    }
    getActiveExam() {
        return this._http.get(config.apiEndPointBase + "academia/examination/active/");
    }
    examination() {
        return this._http.get(config.apiEndPointBase + "academia/examinations");
    }
}
AcademiaService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AcademiaService_Factory() { return new AcademiaService(i0.ɵɵinject(i1.HttpClient)); }, token: AcademiaService, providedIn: "root" });
