import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { config } from '../config';
import { map } from 'rxjs/operators';
import { StaffModel } from '../models/staff.model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class EmployeeService {
    constructor(_http) {
        this._http = _http;
        this.onStudentsUpdated = new Subject();
    }
    getEmployeess() {
        return this._employees;
    }
    getEmployee(id) {
        if (this._employees == null)
            return null;
        var items = this._employees.filter((x) => x.id == id);
        if (items.length > 0) {
            return items[0];
        }
        else {
            return null;
        }
    }
    resetPassword(email) {
        var data = { email: email };
        return this._http
            .post(config.apiEndPointBase + "auth/reset-password", data, {})
            .pipe(map((response) => {
            return response;
        }));
    }
    auth(data) {
        return this._http
            .post(config.apiEndPointBase + "auth/validate-join-date", data, {})
            .pipe(map((response) => {
            return response;
        }));
    }
    emailExists(data) {
        return this._http
            .post(config.apiEndPointBase + "auth/email-exists", data, {})
            .pipe(map((response) => {
            return response;
        }));
    }
    saveEmployee(data) {
        if (data.id) {
            return this._http
                .put(config.apiEndPointBase + "employee", data, {})
                .pipe(map((response) => {
                return new StaffModel().deserialize(response);
            }));
        }
        else {
            return this._http
                .post(config.apiEndPointBase + "employee", data, {})
                .pipe(map((response) => {
                return new StaffModel().deserialize(response);
            }));
        }
    }
}
EmployeeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EmployeeService_Factory() { return new EmployeeService(i0.ɵɵinject(i1.HttpClient)); }, token: EmployeeService, providedIn: "root" });
