import { FormBuilder, Validators } from '@angular/forms';
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
export class studentFormBuilder {
    constructor(fb) {
        this.fb = fb;
        this.validationRules = {
            id: ['', ''],
            academicProgramId: ['', Validators.required],
            name: ['', Validators.required],
            academicSessionId: ['', Validators.required],
            address: ['', Validators.required],
            batchStartYear: ['', Validators.required],
            bloodGroup: ['', ''],
            caste: ['', Validators.required],
            classRollNumber: ['', Validators.required],
            currentSemester: ['', Validators.required],
            discontinued: ['', ''],
            email: ['', ''],
            emailSignature: ['', ''],
            registrationNumber: ['', Validators.required],
            examinationRollNumber: ['', ''],
            gender: ['', Validators.required],
            mobile: ['', ''],
            parentName: ['', ''],
            percentile: ['', ''],
            registrationYear: ['', ''],
            dateOfBirth: ['', ''],
            nadId: ['', ''],
            aadharNumber: ['', ''],
            unqualified: ['', ''],
            nationality: ['', ''],
            pwD: ['', ''],
            thesis: ['', ''],
            blocked: ['', ''],
            migrated: ['', ''],
        };
        this.form = fb.group(this.validationRules);
    }
    getForm() {
        return this.form;
    }
    setValues(response) {
        this.form.patchValue({
            id: response.id,
            academicProgramId: response.academicProgramId,
            name: response.name,
            registrationNumber: response.registrationNumber,
            academicSessionId: response.academicSessionId,
            address: response.address,
            batchStartYear: response.batchStartYear,
            bloodGroup: response.bloodGroup,
            caste: response.caste,
            classRollNumber: response.classRollNumber,
            currentSemester: response.currentSemester,
            discontinued: response.discontinued,
            email: response.email,
            emailSignature: response.emailSignature,
            examinationRollNumber: response.examinationRollNumber,
            gender: response.gender,
            mobile: response.mobile,
            parentName: response.parentName,
            percentile: response.percentile,
            registrationYear: response.registrationYear,
            unqualified: response.unqualified,
            dateOfBirth: response.dateOfBirth,
            nadId: response.nadId,
            aadharNumber: response.aadharNumber,
            nationality: response.nationality,
            pwD: response.pwD,
            thesis: response.thesis,
            blocked: response.blocked,
            migrated: response.migrated
        });
    }
}
studentFormBuilder.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function studentFormBuilder_Factory() { return new studentFormBuilder(i0.ɵɵinject(i1.FormBuilder)); }, token: studentFormBuilder, providedIn: "root" });
