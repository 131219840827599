import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { config } from '../../app/config';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class DepartmentService {
    constructor(_http) {
        this._http = _http;
    }
    getDisciplines(departmentId) {
        return this._http.get(config.apiEndPointBase + "department/" + departmentId + "/disciplines");
    }
    getEmployees(departmentId) {
        return this._http.get(config.apiEndPointBase + "department/" + departmentId + "/employees");
    }
    getEmployee(employeeId) {
        return this._http.get(config.apiEndPointBase + "employee/" + employeeId);
    }
    getDepartment(departmentId) {
        return this._http.get(config.apiEndPointBase + "department/" + departmentId);
    }
    linkUnlinkEmployee(data, type) {
        return this._http
            .put(config.apiEndPointBase + "department/" + data.departmentId + "/employee/" + type + "/" + data.employeeId, {}, {})
            .pipe(map((response) => {
            return response;
        }));
    }
    markeAsHod(data) {
        return this._http
            .put(config.apiEndPointBase + "department/" + data.departmentId + "/mark-hod/" + data.employeeId, {}, {})
            .pipe(map((response) => {
            return response;
        }));
    }
}
DepartmentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DepartmentService_Factory() { return new DepartmentService(i0.ɵɵinject(i1.HttpClient)); }, token: DepartmentService, providedIn: "root" });
